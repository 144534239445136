<template>
  <div>
    <h1>Request a free demo or call</h1>

    <p>Request a free 15-30 minute demo or call using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

    <p>During the demo we can show how easy it is to go virtual with your event or concept. We can zoom into specific topics or areas or first start with the general picture.</p>

<!--
    <script type="application/javascript" src='https://noctilucentbv.myfreshworks.com/crm/sales/web_forms/bde4a2eb1dbd8e783f50bcad2de0184a52acc9282fe52545108f798a6b448b6e/form.js' crossorigin='anonymous' id='fs_bde4a2eb1dbd8e783f50bcad2de0184a52acc9282fe52545108f798a6b448b6e'></script> 
-->
    <v-form lazy-validation ref="form" action="https://formspree.io/f/mpzknpqw" method="POST">

      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Your Name"
        name="name"
        required
      ></v-text-field>


      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        name="_replyto"
        type="email"
        required
      ></v-text-field>

      <v-text-field label="Date and time preference" name="when" hint="When would you prefer to have this demo? Please include your time zone." />

      <v-textarea v-model="comments" class="mb-8" label="Specific topics, features or comments:" persistent-hint hint="Let us know if you have specific questions or features you'd like to include in the demo." name="comments"></v-textarea>
      <input type="hidden" name="timezone" :value="timeZoneOlson"/>
    
      <v-btn class="primary" type="submit">Submit</v-btn>
    </v-form>
  </div>
</template>


<script>
import { DateTime } from 'luxon'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      valid: true,
      timeZoneOlson: null,
      name: '',
      org: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: ''
    };
  },
  created() {
  },
  mounted() {
    this.timeZoneOlson = DateTime.now().zoneName;
  },
  methods: {
    submit () {
        if (this.$refs.form.validate()) {
        }
      }
  },
  watch: {
  }
};
</script>

<style scoped>
</style>